import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import EditAvatar from './buttons/EditAvatar';
import isDataEmpty from '../../helpers/shared/isDataEmpty';
import { formatPhoneNumberDigitsOnly } from '../../utilities/formatPhoneNumber';
import { userActions } from '../../containers/User';
import { modalActions } from '../../containers/Modal';
import GenericTextInput from '../inputs/GenericTextInput';
import deepCopy from '../../utilities/deepCopy';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import LoadingButton from '@mui/lab/LoadingButton';

export const UserInformationSection = ({
	t,
	user,
	isUpdatingUser,
	userAvatarSrc,
	resetUpdateUser,
	userHasBeenUpdated,
	editAvatar,
	resetUserAvatarUpload,
	updateUser
}) => {
	const [updatedUser, setUpdatedUser] = React.useState(user);
	const [userInformationHasBeenModified, setUserInformationHasBeenModified] =
		React.useState(false);

	React.useEffect(() => {
		if (!isDataEmpty(user)) {
			setUpdatedUser(user);
		}
	}, [user]);

	React.useEffect(() => {
		return () => {
			resetUpdateUser();
		};
	}, []);

	const onUpdateUserInformation = (value, key) => {
		const newUpdate = deepCopy(updatedUser);
		newUpdate[key] = value;

		setUpdatedUser(newUpdate);
		setUserInformationHasBeenModified(true);
	};

	const onSubmitUserUpdate = () => {
		updatedUser.phone = formatPhoneNumberDigitsOnly(updatedUser.phone);
		updatedUser.avatar = undefined;

		updateUser({
			userId: user.id,
			username: user.username,
			userData: updatedUser
		});
	};

	const onEditAvatar = () => {
		resetUserAvatarUpload();
		editAvatar();
	};
	return (
		<Stack p={1} spacing={2}>
			<Stack direction='row' alignItems='center'>
				<Typography variant='h5'>
					{t('account:userInformation.header')}
				</Typography>
			</Stack>
			<Stack direction='row' alignItems='center' spacing={2}>
				<Badge
					overlap='circular'
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					badgeContent={<EditAvatar onClick={onEditAvatar} />}
				>
					<Avatar
						alt={user.firstName}
						src={userAvatarSrc}
						variant='rounded'
						sx={{ width: 100, height: 100 }}
					/>
				</Badge>
				<Stack>
					<Grid container spacing={2}>
						<Grid item>
							<GenericTextInput
								label={t('account:userInformation.firstName')}
								onUpdate={(value) =>
									onUpdateUserInformation(value, 'firstName')
								}
								value={updatedUser?.firstName}
							/>
						</Grid>
						<Grid item>
							<GenericTextInput
								label={t('account:userInformation.lastName')}
								onUpdate={(value) => onUpdateUserInformation(value, 'lastName')}
								value={updatedUser?.lastName}
							/>
						</Grid>
						<Grid item>
							<GenericTextInput
								label={t('account:userInformation.email')}
								onUpdate={(value) => onUpdateUserInformation(value, 'email')}
								value={updatedUser?.email}
							/>
						</Grid>
						<Grid item>
							<GenericTextInput
								label={t('account:userInformation.phone')}
								onUpdate={(value) => onUpdateUserInformation(value, 'phone')}
								value={updatedUser?.phone}
							/>
						</Grid>
					</Grid>
					<Stack
						direction='row'
						mt={2}
						justifyContent='flex-end'
						alignItems='center'
						spacing={2}
					>
						{userInformationHasBeenModified && userHasBeenUpdated ? (
							<Alert severity='success'>
								{t('account:userInformation.successfullyUpdated')}
							</Alert>
						) : null}
						<LoadingButton
							variant='outlined'
							color='primary'
							onClick={onSubmitUserUpdate}
							loading={isUpdatingUser}
						>
							{t('common:buttons.update')}
						</LoadingButton>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

UserInformationSection.propTypes = {
	t: PropTypes.func,
	user: PropTypes.object,
	isUpdatingUser: PropTypes.bool,
	userAvatarSrc: PropTypes.string,
	resetUpdateUser: PropTypes.func,
	userHasBeenUpdated: PropTypes.bool,
	editAvatar: PropTypes.func,
	resetUserAvatarUpload: PropTypes.func,
	updateUser: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		isUpdatingUser: state.userReducer.get('isUpdatingUser'),
		userHasBeenUpdated: state.userReducer.get('userHasBeenUpdated'),
		userAvatarSrc: state.userAvatarSlice.userAvatar.src
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (payload) => {
			dispatch(userActions.updateUser(payload));
		},
		editAvatar: () => {
			dispatch(modalActions.toggleUpdateAvatarModal());
		},
		resetUserAvatarUpload: (payload) => {
			dispatch(userActions.resetUserAvatarUpload(payload));
		},
		resetUpdateUser: (payload) => {
			dispatch(userActions.resetUpdateUser(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['account', 'common'])(UserInformationSection));
