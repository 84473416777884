import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import Loader from '../../components/Loader';
import { userActions } from '../../containers/User';

const challengeRouting = {
	PasswordResetRequiredException: '/password-reset',
	NEW_PASSWORD_REQUIRED: '/password-setup',
	SOFTWARE_TOKEN_MFA: '/multi-factor-auth'
};

const PostLoginManager = ({
	authUser,
	isAuthed,
	challengeName,
	userHasBeenLoaded,
	getUser
}) => {
	const navigate = useNavigate();

	React.useEffect(() => {
		if (isAuthed && challengeName) {
			navigate(challengeRouting[challengeName], {
				state: {
					username: authUser.username
				}
			});
		}
	}, [isAuthed, challengeName]);

	React.useEffect(() => {
		if (!userHasBeenLoaded && authUser?.username && !challengeName) {
			getUser(authUser.username);
		}
	}, [isAuthed]);
	React.useEffect(() => {
		if (isAuthed && userHasBeenLoaded && !challengeName) {
			navigate('/dashboard');
		}
	}, [isAuthed, userHasBeenLoaded]);

	return <Loader />;
};

PostLoginManager.propTypes = {
	authUser: PropTypes.object,
	isAuthed: PropTypes.bool,
	isAuthingUser: PropTypes.bool,
	challengeName: PropTypes.string,
	logInError: PropTypes.string,
	userHasBeenLoaded: PropTypes.bool,
	isLoadingUser: PropTypes.bool,
	getUser: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authReducer.get('user'),
		isAuthed: state.authReducer.get('isAuthed'),
		isAuthingUser: state.authReducer.get('isAuthingUser'),
		challengeName: state.authReducer.get('challengeName'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded'),
		isLoadingUser: state.userReducer.get('isLoadingUser'),
		logInError: state.authReducer.get('logInError')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUser: (payload) => {
			dispatch(userActions.getUser(payload));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PostLoginManager);
