import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PaginatedTable from '../../../table/PaginatedTable';
import NotificationRuleRow from './components/NotificationRuleRow';
import notificationRuleColumns from './constants/columnDefinitions';

import { getNotificationRuleReport } from '../../../../containers/Reports/reportsSlice';

import Stack from '@mui/material/Stack';

const NotificationRuleReportTable = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		data,
		currentPage,
		availablePageCount,
		hasBeenLoaded,
		isLoading,
		error
	} = useSelector((state) => state.reportsSlice.notificationRuleReport);

	const onPageChange = (newPage) => {
		const updatedSearchParams = {
			startDate: searchParams.get('startDate'),
			endDate: searchParams.get('endDate'),
			notificationGenerated: searchParams.get('notificationGenerated'),
			resultsPerPage: searchParams.get('resultsPerPage'),
			page: newPage
		};

		const severityId = searchParams.get('severityId');
		const category = searchParams.get('category');

		if (severityId) {
			searchParams.severityId = severityId;
		}

		if (category) {
			searchParams.category = category;
		}

		setSearchParams(updatedSearchParams);
		dispatch(
			getNotificationRuleReport({
				queryParameterString: updatedSearchParams
			})
		);
	};

	const onClickViewNotification = (notificationId) => {
		navigate(`/notifications/${notificationId}`);
	};

	const generateRows = React.useCallback(() => {
		return data?.map((row) => (
			<NotificationRuleRow
				key={row?.userRuleRunId}
				row={row}
				onClickViewNotification={onClickViewNotification}
			/>
		));
	}, [data]);

	return (
		<Stack direction='column' spacing={2} mt={2} mb={2} p={1}>
			<PaginatedTable
				data={data}
				reportName={'notificationRuleReport'}
				isLoading={isLoading}
				hasBeenLoaded={hasBeenLoaded}
				columnDefinitions={notificationRuleColumns}
				currentPage={currentPage}
				handleChangePage={onPageChange}
				availablePageCount={availablePageCount}
				rowGenerator={generateRows}
				error={error}
			/>
		</Stack>
	);
};

NotificationRuleReportTable.propTypes = {
	executeReport: PropTypes.func,
	reportDataList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	clearReportData: PropTypes.func
};

export default NotificationRuleReportTable;
