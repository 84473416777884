import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const initialState = fromJS({
	user: {},
	challengeName: undefined,
	hasCheckedUserSession: false,
	isAuthed: false,
	isAuthingUser: false,
	logInError: undefined,
	passwordSetup: {
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	passwordReset: {
		isSendingResetCode: false,
		hasSentResetEmail: false,
		isResettingPassword: false,
		hasCompletedPasswordReset: false,
		passwordResetError: undefined
	}
});

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_USER:
			return state.set('isAuthingUser', true).set('challengeName', undefined);

		case actionTypes.AUTH_USER_COMPLETED:
			return state
				.set('isAuthingUser', false)
				.set('user', action.payload.user)
				.set('challengeName', action.payload.challengeName)
				.set('isAuthed', true);

		case actionTypes.AUTH_USER_FAILED:
			return state
				.set('isAuthingUser', false)
				.set('isAuthed', false)
				.set('logInError', action.payload);

		case actionTypes.AUTH_USER_SESSION:
			return state.set('isAuthingUser', true);

		case actionTypes.AUTH_USER_SESSION_COMPLETED:
			return state
				.set('isAuthingUser', false)
				.set('user', action.payload)
				.set('isAuthed', true)
				.set('hasCheckedUserSession', true);

		case actionTypes.AUTH_USER_SESSION_FAILED:
			return state
				.set('isAuthingUser', false)
				.set('isAuthed', false)
				.set('hasCheckedUserSession', true);

		case actionTypes.DE_AUTH_USER:
			return initialState;

		case actionTypes.COMPLETE_PASSWORD_SETUP:
			return state.setIn(['passwordSetup', 'isLoading'], true);

		case actionTypes.COMPLETE_PASSWORD_SETUP_COMPLETED:
			return state
				.setIn(['passwordSetup', 'isLoading'], false)
				.setIn(['passwordSetup', 'hasBeenLoaded'], true)
				.set('challengeName', undefined)
				.set('logInError', undefined)
				.set('isAuthed', false);

		case actionTypes.COMPLETE_PASSWORD_SETUP_FAILED:
			return state
				.setIn(['passwordSetup', 'isLoading'], true)
				.setIn(['passwordSetup', 'error'], action.payload);

		case actionTypes.SEND_PASSWORD_RESET_CODE:
			return state.setIn(['passwordReset', 'isSendingResetCode'], true);

		case actionTypes.SEND_PASSWORD_RESET_CODE_COMPLETED:
			return state
				.setIn(['passwordReset', 'isSendingResetCode'], false)
				.setIn(['passwordReset', 'hasSentResetEmail'], true);

		case actionTypes.SEND_PASSWORD_RESET_CODE_FAILED:
			return state
				.setIn(['passwordReset', 'hasSentResetEmail'], false)
				.setIn(['passwordReset', 'isSendingResetCode'], false);

		case actionTypes.RESET_PASSWORD:
			return state
				.setIn(['passwordReset', 'isResettingPassword'], true)
				.setIn(['passwordReset', 'passwordResetError'], undefined)
				.setIn(['passwordReset', 'challengeName'], undefined);

		case actionTypes.RESET_PASSWORD_COMPLETED:
			return state
				.setIn(['passwordReset', 'isResettingPassword'], false)
				.setIn(['passwordReset', 'hasCompletedPasswordReset'], true);

		case actionTypes.RESET_PASSWORD_FAILED:
			return state
				.setIn(['passwordReset', 'isResettingPassword'], false)
				.setIn(['passwordReset', 'hasCompletedPasswordReset'], false)
				.setIn(['passwordReset', 'passwordResetError'], action.payload);

		default:
			return state;
	}
};
