import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { authActions } from '../../containers/Auth';
import { useNavigate } from 'react-router';
import { landingPageURI } from '../../utilities/apiEndpointUtility';
import {
	clearLocalStorage,
	getLocalStorage
} from '../../utilities/localStorageUtility';
import SecureInput from '../inputs/SecureInput';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import BorderedPaper from '../branded/BorderedPaper';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';
import OverlineTextError from '../errors/OverlineTextError';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const LoginFormTypography = styled(Typography)({
	fontWeight: 600,
	textDecoration: 'none!important',
	cursor: 'pointer'
});

const LoginForm = ({ t, logInError, isAuthed, isAuthingUser, authUser }) => {
	const [password, setPassword] = React.useState('');
	const [userInfo, setUserInfo] = React.useState();

	const navigate = useNavigate();

	const getStoredInfo = React.useCallback(() => {
		const cpiInfo = getLocalStorage('CPI');

		if (!cpiInfo.UNAME || !cpiInfo.ORG) {
			window.location.replace(landingPageURI());
		} else {
			setUserInfo({
				organization: cpiInfo.ORG,
				username: cpiInfo.UNAME
			});
		}
	}, [setUserInfo]);

	React.useEffect(() => {
		if (!userInfo) {
			getStoredInfo();
		}
	}, [userInfo]);

	React.useEffect(() => {
		if (isAuthed) {
			setPassword('');
			navigate('/post-login');
		}
	}, [isAuthed]);

	const handleRemoveLocalStorage = () => {
		clearLocalStorage();
		window.location.replace(landingPageURI());
	};

	const onSubmit = () => {
		authUser(userInfo.username, password);
	};

	const onForgotPassword = () => {
		navigate('/forgot-password');
	};
	return (
		<BorderedPaper>
			<Stack p={2} mx={4}>
				<Stack direction='row' justifyContent='center'>
					<BoldLargeHeader py={2}>
						{t('registration:loginForm.memberSignIn')}
					</BoldLargeHeader>
				</Stack>
				<Stack direction='row' justifyContent='center' my={1}>
					<OverlineTextError>{logInError || ' '}</OverlineTextError>
				</Stack>
				<Stack spacing={2}>
					<Box>
						<FormControl sx={{ width: '100%' }}>
							<TextField
								disabled
								label={t('registration:loginForm.username')}
								value={userInfo ? userInfo.username : ''}
								size='small'
							/>
						</FormControl>
						<Stack direction='row' justifyContent='flex-end' mt={0.5}>
							<LoginFormTypography
								variant='caption'
								onClick={handleRemoveLocalStorage}
								color='secondary'
							>
								{t('registration:loginForm.changeUser')}
							</LoginFormTypography>
						</Stack>
					</Box>
					<FormControl>
						<SecureInput
							label={t('registration:loginForm.enterPassword')}
							onChange={(value) => setPassword(value)}
							value={password}
							onEnterListener={onSubmit}
						/>
					</FormControl>
					<Stack direction='row' justifyContent='center'>
						<ContainedLoadingButton
							bgcolor='primary.alt'
							loading={isAuthingUser}
							disabled={!userInfo?.username || password.length < 1}
							onClick={onSubmit}
						>
							{t('common:buttons.submit')}
						</ContainedLoadingButton>
					</Stack>
				</Stack>
			</Stack>
			<Stack direction='row' justifyContent='flex-start' p={2}>
				<LoginFormTypography
					variant='caption'
					sx={{ color: 'primary.alt' }}
					onClick={onForgotPassword}
				>
					{t('registration:loginForm.forgotPassword')}
				</LoginFormTypography>
			</Stack>
		</BorderedPaper>
	);
};

LoginForm.propTypes = {
	t: PropTypes.func,
	logInError: PropTypes.string,
	isAuthed: PropTypes.bool,
	isAuthingUser: PropTypes.bool,
	authUser: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		challengeName: state.authReducer.get('challengeName'),
		logInError: state.authReducer.get('logInError'),
		isAuthingUser: state.authReducer.get('isAuthingUser'),
		isAuthed: state.authReducer.get('isAuthed')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authUser: (username, password) => {
			dispatch(authActions.authUser(username, password));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['registration', 'common'])(LoginForm));
