import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import DateSelect from '../../../inputs/DateSelect';
import OutlinedLoadingButton from '../../../buttons/OutlinedLoadingButton';
import LevelSelect from '../../../rules/LevelSelect';
import NotificationGeneratedSelect from './components/NotificationGeneratedSelect';
import {
	getNotificationRuleReport,
	resetGetNotificationRuleReport
} from '../../../../containers/Reports/reportsSlice';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

const NotificationRuleReportFilters = ({ t }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();

	const [startDate, setStartDate] = React.useState(dayjs().add(-7, 'day'));
	const [endDate, setEndDate] = React.useState(dayjs());
	const [selectedLevel, setSelectedLevel] = React.useState('all');
	const [selectedCategory, setSelectedCategory] = React.useState('all');
	const [notificationGenerated, setNotificationGenerated] =
		React.useState('all');

	const { isLoading, error } = useSelector(
		(state) => state.reportsSlice.notificationRuleReport
	);

	React.useEffect(() => {
		onClickApply();

		return () => {
			dispatch(resetGetNotificationRuleReport());
		};
	}, []);

	const onClickApply = () => {
		const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
		const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

		dispatch(
			getNotificationRuleReport({
				queryParameterString: {
					startDate: formattedStartDate,
					endDate: formattedEndDate,
					severityId: selectedLevel?.id,
					category: selectedCategory?.name,
					notificationGenerated,
					resultsPerPage: 25,
					page: 1
				}
			})
		);

		const searchParams = {
			startDate: formattedStartDate,
			endDate: formattedEndDate,
			notificationGenerated,
			resultsPerPage: 25,
			page: 1
		};

		if (selectedLevel?.name) {
			searchParams.severityId = selectedLevel?.id;
		}

		if (selectedCategory?.name) {
			searchParams.category = selectedCategory?.name;
		}

		setSearchParams(searchParams);
	};

	return (
		<Stack direction='column' p={2} component={Paper} mt={2}>
			<Grid container alignItems='center' spacing={1}>
				<Grid item xs={12} sm={12} md={6} lg={3}>
					<DateSelect
						value={startDate}
						onChangeDate={setStartDate}
						disabledFuture
						maxDate={endDate}
						label={t('reports:startDate')}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={3}>
					<DateSelect
						value={endDate}
						onChangeDate={setEndDate}
						disabledFuture
						minDate={startDate}
						label={t('reports:endDate')}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={3}>
					<LevelSelect
						selectedLevel={selectedLevel}
						onChangeSelection={setSelectedLevel}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={3}>
					<NotificationGeneratedSelect
						selectedOption={notificationGenerated}
						onChangeSelection={setNotificationGenerated}
					/>
				</Grid>
				<Grid
					item
					xs={0}
					sm={0}
					md={9}
					lg={10}
					sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}
				/>
				<Grid item xs={12} sm={12} md={3} lg={2}>
					<OutlinedLoadingButton onClick={onClickApply} loading={isLoading}>
						{t('common:buttons.submit')}
					</OutlinedLoadingButton>
				</Grid>
			</Grid>
		</Stack>
	);
};

NotificationRuleReportFilters.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['common', 'reports'])(
	NotificationRuleReportFilters
);
