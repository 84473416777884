import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import PaginatedTableRow from '../../../../table/components/PaginatedTableRow';
import NotificationSeverityIcon from '../../../../shared/NotificationSeverityIcon';
import OutlinedButton from '../../../../buttons/OutlinedButton';
import { ruleBuilderCategoryIcons } from '../../../../../icons';
import { capitalCasing } from '../../../../../utilities/changeCase';
import { formatDateForDisplay } from '../../../../../utilities/dateWrapper';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const CategoryIconBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	'& .icon': {
		fontSize: '2rem',
		color: theme.palette.secondary.light,
		opacity: 0.5
	}
}));

const NotificationRuleRow = ({ t, row, onClickViewNotification }) => {
	const CategoryIcon = ruleBuilderCategoryIcons[row.ruleCategory];

	return (
		<PaginatedTableRow py={1} height={65}>
			<TableCell>
				<Stack direction='row' spacing={1} justifyContent='center'>
					<Box width={55}>
						<Typography sx={{ fontSize: '1rem' }}>
							{formatDateForDisplay(row.completedOn, 'M.D.YY')}
						</Typography>
					</Box>
					<Divider orientation='vertical' flexItem />
					<Box width={80}>
						<Typography sx={{ fontSize: '1rem' }}>
							{formatDateForDisplay(row.completedOn, 'hh:mm A')}
						</Typography>
					</Box>
				</Stack>
			</TableCell>
			<TableCell>
				<NotificationSeverityIcon
					severity={row.severity}
					fontSizeOverride={'2rem'}
				/>
			</TableCell>
			<TableCell align='center'>
				{row.ruleCategory ? (
					<CategoryIconBox>
						<Tooltip title={capitalCasing(t(row.ruleCategory))}>
							<CategoryIcon className='icon' />
						</Tooltip>
					</CategoryIconBox>
				) : (
					'-'
				)}
			</TableCell>
			<TableCell>
				<Typography>{row.notificationDescription}</Typography>
				{row?.notificationDescription === 'Not enough data to run rule.' ? (
					<Typography variant='caption'>{row.userRuleDescription}</Typography>
				) : null}
			</TableCell>
			<TableCell align='center'>
				{row.notificationId ? (
					<OutlinedButton
						size='small'
						onClick={() => {
							onClickViewNotification(row.notificationId);
						}}
					>
						{t('notificationRuleReport.view')}
					</OutlinedButton>
				) : (
					t('notificationRuleReport.didNotTrigger')
				)}
			</TableCell>
		</PaginatedTableRow>
	);
};

NotificationRuleRow.propTypes = {
	row: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	onClickViewNotification: PropTypes.func,
	rowIndex: PropTypes.number,
	t: PropTypes.func
};

export default withTranslation('reports')(NotificationRuleRow);
