const mapConfigurationForSubmit = (user, ruleConfiguration) => {
	const ruleListTypeId = ruleConfiguration.ruleListType?.id;

	return {
		userSelections: [
			{
				key: 'formula',
				fieldId: ruleConfiguration.formula.fieldId,
				optionId: ruleConfiguration.formula.optionId
			},
			{
				key: 'measurement',
				fieldId: ruleConfiguration.measurement.fieldId,
				optionId: ruleConfiguration.measurement.optionId
			},
			{
				key: 'paymentMethod',
				fieldId: ruleConfiguration.paymentMethod.fieldId,
				optionId: ruleConfiguration.paymentMethod.optionId
			},
			{
				key: 'comparator',
				fieldId: ruleConfiguration.comparator.fieldId,
				optionId: ruleConfiguration.comparator.optionId
			},
			{
				key: 'comparisonUnit',
				fieldId: ruleConfiguration.comparisonUnit.fieldId,
				optionId: ruleConfiguration.comparisonUnit.optionId,
				userInput: ruleConfiguration.userInput
			},
			{
				key: 'timeFrame',
				fieldId: ruleConfiguration.timeFrame.fieldId,
				optionId: ruleConfiguration.timeFrame.optionId
			}
		],
		label: ruleConfiguration.label,
		categoryId: ruleConfiguration.category?.id,
		ruleListTypeId: ruleListTypeId,
		groupId: ruleListTypeId === 1 ? ruleConfiguration.group?.id : null,
		processorId:
			ruleListTypeId === 2
				? ruleConfiguration.processor?.paymentEnablerAcquiringBankId
				: null,
		merchantIds:
			ruleListTypeId === 3
				? ruleConfiguration.merchantIds?.map((merchant) => merchant.midId)
				: null,
		affiliateIds:
			ruleListTypeId === 4
				? ruleConfiguration.affiliateIds?.map(
						(affiliate) => affiliate.affiliateId
					)
				: null,
		severityId: ruleConfiguration.severity?.id,
		runFrequency: ruleConfiguration.runFrequency,
		ignoreNonTriggered: ruleConfiguration.ignoreNonTriggered,
		autoAssignUserId: ruleConfiguration.autoAssignUserId?.id,
		userId: user.id
	};
};

export default mapConfigurationForSubmit;
